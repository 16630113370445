
import { defineAsyncComponent, defineComponent, ref } from "vue";
import { useUserStore } from "@/stores/user";
import {AxiosResponse} from "axios";

export default defineComponent({
  name: "CreateManager",
  components: {
    Modale: defineAsyncComponent(() => import('@/modales/Modale.vue')),
    Card: defineAsyncComponent(() => import('@/components/Card.vue')),
    Input: defineAsyncComponent(() => import('@/components/Input.vue')),
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    Loader: defineAsyncComponent(() => import('@/components/Loader.vue')),
  },
  props: {
    isOpen: { type: Boolean, default: 'false' },
  },
  setup(props, { emit }) {
    //STORE
    const usersStore = useUserStore();

    //DATA
    const loader = ref(false);
    const step = ref(1);
    const width = ref('30');
    const height = ref('50');
    //step1
    const email = ref('');
    const password = ref('');
    const firstname = ref('');
    const lastname = ref('');
    const companyName = ref('');

    const createUser = () => {
      loader.value = true;
      usersStore.signup({
        email: email.value,
        password: password.value,
        firstname: firstname.value,
        lastname: lastname.value,
        companyName: companyName.value,
      }).then(() => {
        loader.value = false;
        step.value = 1;
        width.value = '30';
        height.value = '50';
        emit('createUser');
      });
    };

    const close = () => {
      step.value = 1;
      width.value = '30';
      height.value = '50';
      emit('close');
    }

    return {  createUser,  loader, step,
      email, password, firstname, lastname, companyName,
      width, height, close };
  },
});
