
import { defineComponent } from "vue";

export default defineComponent({
  name: "Modale",
  props: {
    width: { type: String, default: '70' },
    height: { type: String, default: '70' },
    title: { type: String, default: '' },
  },
  setup() {
  },
});
