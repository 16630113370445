
import {defineAsyncComponent, defineComponent} from "vue";
import {randomColor} from '@/functions/utils';

export default defineComponent({
  name: "TableItem",
  components: {
    CardButton: defineAsyncComponent(() => import('@/components/CardButton.vue')),
    Checkbox: defineAsyncComponent(() => import('@/components/Checkbox.vue')),
  },
  props: {
    item: { type: Object, default: { id: '', logo: '', fields: [], actions: []}},
    id: { type: String, default: '' },
    isCheck: { type: Boolean, default: false },
  },
  setup () {
    return { randomColor };
  }
});
