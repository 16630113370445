
import { defineAsyncComponent, defineComponent } from "vue";

export default defineComponent({
  name: "CardFilter",
  components: {
    Card: defineAsyncComponent(() => import('@/components/Card.vue')),
  },
  props: {
    width: { type: String, default: '100' },
    height: { type: String, default: '200' },
    title: { type: String, default: '' },
    number: { type: Number, default: 0 },
    icone: { type: String, default: '' },
    isActive: { type: Boolean, default: false }
  }
});
