
import { defineAsyncComponent, defineComponent, ref } from "vue";
import { useUserStore } from "@/stores/user";
import api from "@/api/spaceMountainAPI";
import {storeToRefs} from "pinia";

export default defineComponent({
  name: "CreateClient",
  components: {
    Modale: defineAsyncComponent(() => import('@/modales/Modale.vue')),
    Card: defineAsyncComponent(() => import('@/components/Card.vue')),
    Input: defineAsyncComponent(() => import('@/components/Input.vue')),
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    Loader: defineAsyncComponent(() => import('@/components/Loader.vue')),
  },
  props: {
    isOpen: { type: Boolean, default: 'false' },
  },
  setup(props, { emit }) {
    //STORE
    const usersStore = useUserStore();
    const { userLoader } = storeToRefs(usersStore);
    //DATA
    const email = ref('');
    const firstname = ref('');
    const lastname = ref('');
    const password = ref('');

    const createAdmin = () => {
      usersStore.goLoader();
      usersStore.createAdmin(firstname.value, lastname.value, email.value, password.value).then(() => {
        usersStore.stopLoader();
        emit('createUser');
      });
    };

    const close = () => {
      emit('close');
    }

    return { createAdmin, userLoader, email, firstname, lastname, password, close };
  },
});
