
import { defineAsyncComponent, defineComponent, ref } from "vue";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";

export default defineComponent({
  name: "changeLogin",
  components: {
    Modale: defineAsyncComponent(() => import('@/modales/Modale.vue')),
    Input: defineAsyncComponent(() => import('@/components/Input.vue')),
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    Loader: defineAsyncComponent(() => import('@/components/Loader.vue')),
  },
  props: {
    isOpen: { type: Boolean, default: 'false' },
    userId: { type: String, default: '' },
  },
  setup(props, { emit }) {
    //STORE user
    const userStore = useUserStore();
    const { user, userLoader } = storeToRefs(userStore);

    //DATA
    const email = ref('');
    const password = ref('');
    const loader = ref(false);
    const changeInfos = () => {
      loader.value = true;
      userStore.changeLogin(email.value, password.value, props.userId).then(() => {
        emit('close');
      });
    }

    return { email, password, changeInfos, userLoader, user, loader };
  },
});
