import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3150530d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sqg-cardFilter__title" }
const _hoisted_2 = { class: "sqg-cardFilter__number" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, {
    width: _ctx.width,
    height: _ctx.height,
    class: _normalizeClass(['sqg-cardFilter clickable', { '-active' : _ctx.isActive}]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('filter')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("i", {
        class: _normalizeClass(_ctx.icone)
      }, null, 2),
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.title), 1),
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.number), 1)
      ])
    ]),
    _: 1
  }, 8, ["width", "height", "class"]))
}