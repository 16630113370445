
import {defineComponent} from "vue";

export default defineComponent({
  name: "Card",
  props: {
    width: { type: String, default: '100' },
    height: { type: String, default: 'auto' },
  },
});
