import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9c4c9d98"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sqg-modale" }
const _hoisted_2 = { class: "sqg-modale__header" }
const _hoisted_3 = { class: "sqg-modale__title" }
const _hoisted_4 = { class: "sqg-modale__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "sqg-modale__window",
      style: _normalizeStyle(`width: ${_ctx.width}%; height: ${_ctx.height}%;`)
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.title), 1),
        _createElementVNode("i", {
          class: "fas fa-times sqg-modale__close clickable",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ], 4)
  ]))
}